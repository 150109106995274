import { Button, Dropdown } from "antd"
import { useLocation } from "react-router-dom"
import { iconPlus, iconPrevLeft } from "lib/icon"
import { useTranslation } from "react-i18next"

export default function TitlePage({
  title,
  to,
  children,
  titleButton,
  titleButton2,
  isDisplayButton,
  onClick,
  onClick2
}) {
  const { t } = useTranslation()
  const location = useLocation()
  const isEndUserPage = location.pathname.includes('/enduser')

  return (
    <div className="flex items-center w-full mb-2">
      <div className="flex justify-between items-center gap-4 w-full">
        <div className="w-full flex justify-between items-center">
          <div className="font-semibold text-4xl __text">{title}</div>
          {isDisplayButton && !isEndUserPage && (
            <div>
              {!titleButton2 ? (
                <Button type="primary" onClick={onClick}>
                  <div className="flex gap-1 items-center">
                    <span>{iconPlus}</span> <span className="text-[16px]">{titleButton}</span>
                  </div>
                </Button>
              ) : (
                <Dropdown
                  // trigger={["click"]}
                  overlay={
                    <div className="flex flex-col shadow_antd">
                      <Button type="primary" onClick={onClick}>
                        <div className="flex gap-1 items-center">
                          <span>{iconPlus}</span> <span className="text-[16px]">{titleButton}</span>
                        </div>
                      </Button>
                      <Button type="primary" onClick={onClick2}>
                        <div className="flex gap-1 items-center">
                          <span>{iconPlus}</span>{" "}
                          <span className="text-[16px]">{titleButton2}</span>
                        </div>
                      </Button>
                    </div>
                  }
                >
                  <Button type="primary">
                    <div className="flex gap-1 items-center">
                      <span>{iconPlus}</span> <span className="text-[16px]">{t("400_2251")}</span>
                    </div>
                  </Button>
                </Dropdown>
              )}
            </div>
          )}
        </div>
        {children}
      </div>
    </div>
  )
}
