export const iconPlus = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeMiterlimit={1.5}
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-5 h-5"
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
  </svg>
)

export const iconPrevLeft = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeMiterlimit={1.5}
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-6 h-6"
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
  </svg>
)

export const iconWarning = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="#FAAD14"
    className="w-10 h-10"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
    />
  </svg>
)

export const iconLogout = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.61538 18C3.15513 18 2.77083 17.8458 2.4625 17.5375C2.15417 17.2292 2 16.8449 2 16.3846V3.61537C2 3.15512 2.15417 2.77083 2.4625 2.4625C2.77083 2.15417 3.15513 2 3.61538 2H10.0192V3H3.61538C3.46154 3 3.32052 3.0641 3.1923 3.1923C3.0641 3.32052 3 3.46154 3 3.61537V16.3846C3 16.5385 3.0641 16.6795 3.1923 16.8077C3.32052 16.9359 3.46154 17 3.61538 17H10.0192V18H3.61538ZM14.4615 13.5385L13.7596 12.8192L16.0788 10.5H7.1923V9.5H16.0788L13.7596 7.18078L14.4615 6.46152L18 10L14.4615 13.5385Z"
      fill="black"
      fillOpacity="0.65"
    />
  </svg>
)

export const iconUser = (
  <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" width="36" height="36" rx="18" fill="#F5F5F5" />
    <rect x="1" y="0.5" width="35" height="35" rx="17.5" stroke="black" strokeOpacity="0.88" />
    <path
      d="M26.2347 23.6133C25.8136 22.6159 25.2026 21.7099 24.4356 20.9459C23.6709 20.1796 22.7651 19.5687 21.7682 19.1468C21.7593 19.1423 21.7503 19.1401 21.7414 19.1356C23.132 18.1311 24.0361 16.495 24.0361 14.649C24.0361 11.591 21.5584 9.11328 18.5003 9.11328C15.4423 9.11328 12.9646 11.591 12.9646 14.649C12.9646 16.495 13.8686 18.1311 15.2593 19.1378C15.2503 19.1423 15.2414 19.1445 15.2325 19.149C14.2325 19.5709 13.3352 20.1758 12.5651 20.9481C11.7988 21.7128 11.1878 22.6186 10.766 23.6155C10.3515 24.5915 10.128 25.6379 10.1075 26.6981C10.1069 26.7219 10.1111 26.7456 10.1198 26.7678C10.1285 26.79 10.1415 26.8102 10.1582 26.8273C10.1748 26.8443 10.1947 26.8579 10.2167 26.8671C10.2386 26.8764 10.2622 26.8811 10.286 26.8811H11.6253C11.7235 26.8811 11.8017 26.803 11.8039 26.707C11.8485 24.9838 12.5405 23.37 13.7637 22.1468C15.0294 20.8811 16.7102 20.1847 18.5003 20.1847C20.2905 20.1847 21.9713 20.8811 23.2369 22.1468C24.4602 23.37 25.1521 24.9838 25.1968 26.707C25.199 26.8052 25.2771 26.8811 25.3753 26.8811H26.7146C26.7385 26.8811 26.762 26.8764 26.784 26.8671C26.806 26.8579 26.8259 26.8443 26.8425 26.8273C26.8591 26.8102 26.8722 26.79 26.8809 26.7678C26.8896 26.7456 26.8938 26.7219 26.8932 26.6981C26.8709 25.6311 26.6499 24.5932 26.2347 23.6133ZM18.5003 18.4883C17.4758 18.4883 16.5115 18.0887 15.786 17.3633C15.0606 16.6378 14.661 15.6736 14.661 14.649C14.661 13.6244 15.0606 12.6602 15.786 11.9347C16.5115 11.2093 17.4758 10.8097 18.5003 10.8097C19.5249 10.8097 20.4892 11.2093 21.2146 11.9347C21.9401 12.6602 22.3396 13.6244 22.3396 14.649C22.3396 15.6736 21.9401 16.6378 21.2146 17.3633C20.4892 18.0887 19.5249 18.4883 18.5003 18.4883Z"
      fill="black"
      fillOpacity="0.88"
    />
  </svg>
)
export const iconPolicy = (
  <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28.5013 30.3331L32.0246 33.0468C32.2269 33.2117 32.4229 33.2218 32.6126 33.0771C32.7993 32.9325 32.8537 32.7536 32.776 32.5405L31.39 28.1071L35.3263 25.0551C35.5207 24.9214 35.5713 24.7503 35.478 24.5418C35.3846 24.3349 35.2322 24.2315 35.0206 24.2315H30.4403L28.9493 19.6488C28.8715 19.4373 28.7222 19.3315 28.5013 19.3315C28.2804 19.3315 28.1311 19.4373 28.0533 19.6488L26.5623 24.2315H21.982C21.7689 24.2315 21.6133 24.3349 21.5153 24.5418C21.4173 24.7487 21.4663 24.9198 21.6623 25.0551L25.496 28.1071L24.1076 32.6431C24.0299 32.8563 24.0851 33.0351 24.2733 33.1798C24.4615 33.3245 24.6575 33.3151 24.8613 33.1518L28.5013 30.3331ZM28.5013 48.6311C28.2882 48.6311 28.065 48.6117 27.8316 48.5728C27.5983 48.5339 27.3805 48.4756 27.1783 48.3978C22.5847 46.6478 18.9346 43.6798 16.228 39.4938C13.5213 35.3078 12.168 30.7765 12.168 25.8998V15.9061C12.168 15.1128 12.3974 14.3949 12.8563 13.7525C13.3152 13.11 13.9024 12.6434 14.618 12.3525L27.183 7.68581C27.6372 7.52092 28.0766 7.43848 28.5013 7.43848C28.926 7.43848 29.3654 7.52092 29.8196 7.68581L42.3846 12.3525C43.1002 12.6434 43.6874 13.11 44.1463 13.7525C44.6052 14.3965 44.8346 15.1144 44.8346 15.9061V25.8998C44.8346 30.7765 43.4813 35.3078 40.7746 39.4938C38.068 43.6798 34.4179 46.6478 29.8243 48.3978C29.6221 48.4756 29.4043 48.5339 29.171 48.5728C28.9376 48.6117 28.7144 48.6311 28.5013 48.6311ZM28.5013 46.4331C32.5457 45.1498 35.8902 42.5831 38.5346 38.7331C41.1791 34.8831 42.5013 30.6054 42.5013 25.8998V15.8618C42.5013 15.5631 42.4189 15.294 42.254 15.0545C42.0891 14.8149 41.8573 14.636 41.5586 14.5178L28.996 9.85114C28.8451 9.78892 28.6802 9.75781 28.5013 9.75781C28.3224 9.75781 28.1575 9.78892 28.0066 9.85114L15.444 14.5178C15.1453 14.636 14.9135 14.8149 14.7486 15.0545C14.5837 15.294 14.5013 15.5631 14.5013 15.8618V25.8998C14.5013 30.6054 15.8235 34.8831 18.468 38.7331C21.1124 42.5831 24.4569 45.1498 28.5013 46.4331Z"
      fill="black"
      fillOpacity="0.88"
    />
  </svg>
)
export const iconOrg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeMiterlimit={1.5}
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-8 h-8"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z"
    />
  </svg>
)
export const iconApp = (
  <svg width="45" height="46" viewBox="0 0 45 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M30.5702 22.928L22.6447 15.0046L30.5702 7.08105L38.4957 15.0046L30.5702 22.928ZM8.6543 20.4046V9.15746H19.9043V20.4046H8.6543ZM25.0966 36.8427V25.5956H36.3466V36.8427H25.0966ZM8.6543 36.8427V25.5956H19.9043V36.8427H8.6543ZM10.5293 18.5301H18.0293V11.032H10.5293V18.5301ZM30.6171 20.3469L35.9139 15.0514L30.6171 9.7559L25.3202 15.0514L30.6171 20.3469ZM26.9716 34.9682H34.4716V27.4701H26.9716V34.9682ZM10.5293 34.9682H18.0293V27.4701H10.5293V34.9682Z"
      fill="black"
      fillOpacity="0.88"
    />
  </svg>
)
export const iconRole = (
  <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M42.8288 33.375L36 26.625L42.8288 19.875L44.4375 21.4613L39.2175 26.625L44.4375 31.7887L42.8288 33.375ZM36 43.5H33.75V37.875C33.75 36.3832 33.1574 34.9524 32.1025 33.8975C31.0476 32.8426 29.6168 32.25 28.125 32.25H21.375C19.8832 32.25 18.4524 32.8426 17.3975 33.8975C16.3426 34.9524 15.75 36.3832 15.75 37.875V43.5H13.5V37.875C13.5 35.7864 14.3297 33.7834 15.8065 32.3065C17.2834 30.8297 19.2864 30 21.375 30H28.125C30.2136 30 32.2166 30.8297 33.6935 32.3065C35.1703 33.7834 36 35.7864 36 37.875V43.5ZM24.75 14.25C25.8625 14.25 26.9501 14.5799 27.8751 15.198C28.8001 15.8161 29.5211 16.6946 29.9468 17.7224C30.3726 18.7502 30.484 19.8812 30.2669 20.9724C30.0499 22.0635 29.5141 23.0658 28.7275 23.8525C27.9408 24.6391 26.9385 25.1749 25.8474 25.3919C24.7562 25.609 23.6252 25.4976 22.5974 25.0718C21.5696 24.6461 20.6911 23.9251 20.073 23.0001C19.4549 22.0751 19.125 20.9875 19.125 19.875C19.125 18.3832 19.7176 16.9524 20.7725 15.8975C21.8274 14.8426 23.2582 14.25 24.75 14.25ZM24.75 12C23.1925 12 21.6699 12.4619 20.3749 13.3272C19.0798 14.1925 18.0705 15.4224 17.4744 16.8614C16.8784 18.3003 16.7225 19.8837 17.0263 21.4113C17.3302 22.9389 18.0802 24.3421 19.1815 25.4435C20.2829 26.5448 21.6861 27.2948 23.2137 27.5987C24.7413 27.9025 26.3247 27.7466 27.7636 27.1506C29.2026 26.5545 30.4325 25.5452 31.2978 24.2501C32.1631 22.9551 32.625 21.4325 32.625 19.875C32.625 17.7864 31.7953 15.7834 30.3185 14.3065C28.8416 12.8297 26.8386 12 24.75 12Z"
      fill="black"
      fillOpacity="0.88"
    />
  </svg>
)
export const iconResource = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeMiterlimit={1.5}
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-8 h-8"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13.5 21v-7.5a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349M3.75 21V9.349m0 0a3.001 3.001 0 0 0 3.75-.615A2.993 2.993 0 0 0 9.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 0 0 2.25 1.016c.896 0 1.7-.393 2.25-1.015a3.001 3.001 0 0 0 3.75.614m-16.5 0a3.004 3.004 0 0 1-.621-4.72l1.189-1.19A1.5 1.5 0 0 1 5.378 3h13.243a1.5 1.5 0 0 1 1.06.44l1.19 1.189a3 3 0 0 1-.621 4.72M6.75 18h3.75a.75.75 0 0 0 .75-.75V13.5a.75.75 0 0 0-.75-.75H6.75a.75.75 0 0 0-.75.75v3.75c0 .414.336.75.75.75Z"
    />
  </svg>
)
