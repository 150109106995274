// Set the expiration day for local storage items
const expireDay = 1

// Function to set a local storage item with expiration
function setLocalExpire(key, value, day) {
  const now = new Date()
  const item = {
    value: JSON.stringify(value),
    expiry: now.getTime() + day * 86400000 // Calculate expiration time in milliseconds
  }
  localStorage.setItem(key, JSON.stringify(item)) // Store the item in local storage
}

// Function to retrieve a local storage item and check its expiration
function getLocalExpire(key) {
  const itemStr = localStorage.getItem(key)
  if (!itemStr) return null
  const item = JSON.parse(itemStr)
  return item.value // Return the value of the item
}

// Function to set authentication data in local storage with expiration
export function setAuthLocal(auth) {
  setLocalExpire("auth", auth, expireDay)
}

// Function to check and remove expired authentication data from local storage
function checkExpireToken() {
  const itemStr = localStorage.getItem("auth")
  if (!itemStr) return null
  const item = JSON.parse(itemStr)
  const now = new Date()
  if (now.getTime() > item.expiry) {
    removeAuthLocal() // Remove authentication data if it has expired
  }
}

// Function to get authentication data from local storage, checking expiration
export function getAuthLocal() {
  checkExpireToken()
  const auth = getLocalExpire("auth")
  if (auth) {
    return JSON.parse(auth) // Parse and return authentication data if available
  } else {
    return {} // Return an empty object if no authentication data is found
  }
}

// Function to remove all items from local storage
export function removeAuthLocal() {
  let i18nLanguage = localStorage.getItem("i18nLanguage")
  localStorage.clear()
  localStorage.setItem("i18nLanguage", i18nLanguage)
}

// Function to set configuration data in local storage
export function setConfig(type, data) {
  localStorage.setItem(type, JSON.stringify(data)) // Store configuration data in local storage
}

// Function to retrieve configuration data from local storage
export function getConfig(type) {
  const data = localStorage.getItem(type)
  if (data) {
    return JSON.parse(data) // Parse and return configuration data if available
  } else {
    return [] // Return an empty array if no configuration data is found
  }
}
