export const RESOURCE_KEY = {
  ORGANIZATION: "root_gw_organization",
  WRAPPER_MASTER_DATA: "root_wrapper_master_data",
  ROOT_WRAPPER: "root_wrapper",
  ROOT_WRAPPER_GROUP_MASTER_DATA: "root_wrapper_group_master_data",
  ROOT_WRAPPER_GROUP: "root_wrapper_group",
  ROOT_ALERT_RULE_MASTER_DATA: "root_alert_rule_master_data",
  ROOT_ALERT_RULE: "root_alert_rule",
  ROOT_ADMIN_USER: "root_admin_user",
  ROOT_END_USER: "root_end_user",
  ROOT_GROUP: "root_group",
  ROOT_IDM_ORGANIZATION: "root_idm_organization",
  ROOT_POLICY: "root_policy",
  ROOT_ROLE: "root_role",
  ROOT_IDENTITY_PROVIDER: "root_identity_provider",
  ROOT_OAUTH_CLIENT: "root_oauth_client",
  ROOT_APPLICATION: "root_idm_application",
  ROOT_VEHICLE: "root_vehicle",
  ROOT_RESOURCE: "root_resource",
  ROOT_DESIGN_FILE: "root_design_file",
  ROOT_TRANSLATION_VERSION: "root_translation_version",
  UI_MANAGEMENT: "ui_management",
  ROOT_TRANSLATION_TASK: "root_translation_task",
  ROOT_TRANSLATION_ELEMENT: "root_translation_element",
  ROOT_CLIENT_DICTIONARY: "root_client_dictionary",
  ROOT_SYSTEM_ERROR: "root_system_error",
  ROOT_ALL_RESOURCES: "root_all_resources",
  ROOT_PROJECT_RESOURCE: "root_project_resource",
  ROOT_SERVICE: "root_service",
  ROOT_APPROVAL_SETTING: "root_approval_setting",
  ROOT_APPROVAL: "root_approval",
  ROOT_DASHBOARD: "root_dashboard_idm",
  ROOT_RBAC_GROUP_PERMISSION: "root_rbac_group_permission",
  ROOT_RBAC_PERMISSION: "root_rbac_permission",
  ROOT_BY_PASS: "root_by_pass",
  ROOT_CLIENT_REPORT: "root_ac_report"
}

export const HERE_MAP_KEY = "9mYmeeYB-3xOPR2-L0l1-S1ZNw5NYAZtVUGnKluRTEU"

export const SERVICE_CODE = {
  API_CONTROL_PLANE: "api-control-plane",
  API_OM_API_GATEWAY: "api-om-api-gateway",
  API_IDM: "api-idm",
  API_AMS: "api-ams",
  API_COMMOM: "api-common",
  API_NOTI_INAPP: "api-notification-inapp",
  API_ASSET_CONTROL: "api-asset-control",
  API_CHAUFFEUR: "api-chauffeur-connect",
  API_IOT_ACTIVITY: "",
  EXTERNAL_API_ASSET_CONTROL: "asset-control"
}

export const TYPE_BLOCK_NOTI = {
  APPROVAL: 1,
  NEWS: 2,
  MARKETING: 3,
  PROMOTION: 4,
  EVENT: 5,
  TASK: 6
}

export const User_Action = {
  All: "All",
  POST: "POST",
  PUT: "PUT",
  DETAIL: "DETAIL",
  DELETE: "DELETE",
  GET: "GET",
  VIEW: "VIEW",
  CLOSE: "CLOSE",
  ATRACT: "ATRACT",
  APPROVAL: "APPROVAL",
  IMPORT: "IMPORT",
  RESET_PASSWORD: "RESET_PASSWORD"
}

export const ColorDefault = [
  "#FEB139",
  "#4b6bfb",
  "#5B8FF9",
  "#5B8FF9",
  "#FAAD14",
  "#F2CD5C",
  "#A61F69",
  "#3795BD",
  "#D3756B",
  "#A084DC",
  "#A8E890",
  "#069A8E",
  "#FECD1A",
  "#FECD1A"
]

export const OrganizationType = {
  ServiceUser: 1,
  Service_Provider_B2b: 2,
  Service_Provider_B2c: 3
}

export const IdentityProviderType = {
  Oauth2: 1,
  ODIC: 2
}

export const OauthProvider = {
  KDDI: 1
}

export const configLocale = {
  designFileId: process.env.REACT_APP_TRANSLATE_DESIGN_FILE,
  projectId: process.env.REACT_APP_TRANSLATE_PROJECT_ID
}

export const Platform = {
  Web: "web",
  Android: "android",
  Ios: "ios"
}
export const Platform_Enum_Number = {
  Web: 1,
  Android: 2,
  Ios: 3
}

export const EMAIL_TYPE = {
  Confirm: 1,
  Reset: 2
}

export const GENDER_TYPE = {
  MALE: "MALE",
  FEMALE: "FEMALE"
}

export const USER_STATUS_TYPE = {
  ALL: "ALL",
  ACTIVE: "ACTIVE",
  DEACTIVE: "DEACTIVE",
  LOCKED: "LOCKED",
  PENDING: "PENDING",
  DELETE: "DELETE"
}

export const USERS_TYPE = ({
  DRIVER: "DRIVER",
  PASSENGER: "PASSENGER"
})

export const ACTION_TYPE = {
  active: "active",
  deactive: "deactive",
  reset_password: "reset-password",
  delete: "delete"
}

export const DASHBOARD_KEY = {
  roles: "roles",
  end_users: "end_users",
  policies: "policies",
  apps: "apps",
  users: "users",
  organizations: "organizations",
  resources: "resources"
}

export const ORGANIZATION_SETTING_TYPE = {
  adminPassword: 1,
  enduserPassword: 2,
  accessLimit: 3,
  otp: 4
}

export const Setting_Password_Policy = {
  allow_special_character: "allow_special_character",
  alphabetic_letter_required: "alphabetic_letter_required",
  lowercase_letter_required: "lowercase_letter_required",
  uppercase_letter_required: "uppercase_letter_required",
  numbers_required: "numbers_required",
  password_min_length: "password_min_length",
  password_max_length: "password_max_length",
  allowed_character_checked: "allowed_character_checked",
  password_enforce_history: "password_enforce_history",
  special_symbol_required: "special_symbol_required"
}

export const List_Approval_Status = [
  { label: "Not Defined", value: 0, color: "default" },
  { label: "Request", value: 1, color: "warning" },
  { label: "Process", value: 2, color: "processing" },
  { label: "Approved", value: 3, color: "success" }
]

export const Approval_Action_Type = {
  create: "create",
  update: "update",
  approve: "approve",
  reject: "reject"
}

export const Approval_Status = {
  approved: "approved",
  rejected: "rejected",
  in_process: "in_process"
}

export const ERROR_API_CODE = {
  Resource_Not_Found: "IDM_RSNF_404",
  Unable_To_Login: "IDM_IUPWD_400",
  Resource_Deactive: "IDM_RSD_423",
  User_Deactive: "IDM_UD_423",
  User_Verify: "IDM_UVY_410",
  User_Not_Found: "IDM_UNF_404",
  Fail_Parse_Json: "IDM_FTPJB_400",
  Missing_Authorization_Token: "IDM_MAT_404",
  Fail_Validate_Authorization_Token: "IDM_FTVAT_401",
  Fail_Get_Token_Claims: "IDM_FTGTC_400",
  Permission_Not_Allow: "IDM_PMNA_403",
  Item_Already_Exists: "IDM_IAE_409",
  Title_Can_Not_Blank: "IDM_TCNBB_400",
  Item_Not_Found: "IDM_INF_404",
  Record_Not_Found: "IDM_RCNF_404",
  Can_Not_Update_Finished_Item: "IDM_CNUFI_400",
  Data_Provided_Not_Correct: "IDM_TDPINC_400",
  Data_Provided_Forbidden: "IDM_TDPIF_403",
  Acccess_Token_Revoked: "IDM_ACT_401",
  Refresh_Token_Revoked: "IDM_RFT_401",
  Password_Incorrect: "IDM_PWDI_400",
  Username_Already_Exists: "IDM_UAE_409",
  Organization_Already_Exists: "IDM_ORGAE_409",
  Service_Provider_Code_Already_Exists: "IDM_SPCAE_409",
  Verification_Confirm_Code_Expired: "IDM_VUCCE_417",
  Verification_Confirm_Code_Invalid: "IDM_VUCCI_400",
  Verification_Confirm_Code_Not_Found: "IDM_VUCCNF_400",
  Role_Name_Duplicated: "IDM_RND_409",
  Client_Not_Found: "IDM_CLF_404",
  Invalid_Client_Secret: "IDM_ICS_400",
  ClientID_Taken: "IDM_CIDT_400",
  Authorization_Code_Not_Found: "IDM_ACNF_400",
  Authorization_Code_Expired: "IDM_ACE_417",
  Invalid_RedirectURI: "IDM_IRURL_400",
  Invalid_Scope: "IDM_IC_400",
  Refresh_Token_Not_Found: "IDM_RFTNF_404",
  Refresh_Token_Expired: "IDM_RFTE_401",
  Requested_Scope_Cannot_Be_Greater: "IDM_RSCBG_400",
  Error_When_Substring: "ErrorWhenSubstring",
  Err_Get_Not_Found: "IDM_ERRGET_400",
  Err_Updated: "IDM_ERRPUT_400",
  Err_Created: "IDM_ERRPOST_400",
  Err_Deleted: "IDM_ERRDELETE_400",
  Err_Get_Paging_Fail: "IDM_ERRGETPAGINGFAIL_400",
  Err_Get_Param_Fail: "IDM_ERRGETPARAMFAIL_400",
  Err_UserEmail_Duplicated: "IDM_UED_409",

  // CP Error
  Request_Already_Approved: "CP_APPROVAL_ALREADY_APPROVED_ERROR",
  Request_Already_Rejected: "CP_APPROVAL_ALREADY_REJECTED_ERROR",
  Not_Allowed_Approve_Request: "CP_APPROVAL_NOT_ALLOWED_ERROR",

  //GW Error
  Wrapper_Url_Not_Found: "GW0001"
}

export const ACTION_LOGS = (t) => [
  {
    label: t("65_5767"),
    value: "create"
  },
  {
    label: t("33_5133"),
    value: "update"
  },
  {
    label: t("135_8550"),
    value: "delete"
  }
]

export const PHONE_COUNTRY = {
  thaiLand: {
    code: "+66",
    length: 9
  },
  southAfrica: {
    code: "+27",
    length: 9
  },
  japan: {
    code: "+81",
    length: 10
  },
  poland: {
    code: "+590",
    length: 9
  }
}

export const SERVICE_CODE_ORIGANIZATIONS = [
  { value: "api-idm", label: "IDM" },
  { value: "api-control-plane", label: "Control Plane" },
  { value: "api-ams", label: "AMS" },
  { value: "api-om-api-gateway", label: "API Gateway" },
  { value: "api-notification-inapp", label: "Notification" },
  { value: "api-common", label: "Common Service" }
]
