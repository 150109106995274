import { Spin, Tabs, notification, Badge } from "antd"
import { apiNotification, apiApproval } from "api"
import { TYPE_BLOCK_NOTI, Approval_Status } from "lib/Const"
import useComponentVisible from "lib/click-outside"
import { useEffect, useState, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { Link } from "react-router-dom"
// import { Approval_Status } from "lib/Const"
import BoxApproval from "./BoxApproval"
import BoxOther from "./BoxOther"
// import ModalUpdateApproval from "./ModalUpdateApproval"
import { useTranslation } from "react-i18next"

export default function Notifiaction() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)
  const listInnerRef = useRef()
  // const [open, setOpen] = useState(false)
  const [hasNewNoti, setHasNewNoti] = useState(false)
  const [activeKey, setActiveKey] = useState("")
  const [loading, setLoading] = useState(false)
  const [rows, setRows] = useState([])
  const [categories, setCategories] = useState([])
  const __pagination = useRef({
    page_num: 1,
    page_size: 10,
    has_next: false,
    category_id: "",
    user_type: 2
  })
  const __item = useRef(false)

  const fetch = async (noti_id) => {
    setLoading(true)
    try {
      const { data } = await apiNotification.getCategories()
      setCategories(data.filter((v) => v.name !== "Settings"))
      if (data.length > 0) {
        var count = 0
        data.forEach((element) => {
          count += element.count
        })
        // if (count > 0) {
        //   setHasNewNoti(true)
        // } else {
        //   setHasNewNoti(false)
        // }

        if (__pagination.current.category_id !== "") {
          fetchNotiByCategory(noti_id)
        } else {
          const categoryId = data[0]?.id
          __pagination.current.category_id = categoryId
          setActiveKey(categoryId)
          fetchNotiByCategory()
        }
      }
    } catch (e) {
    } finally {
      setLoading(false)
    }
  }

  const fetchNotiByCategory = async (noti_id) => {
    setLoading(true)
    try {
      const resNoti = await apiNotification.getNotificationByCategory(__pagination.current)
      __pagination.current.has_next = resNoti.has_next
      if (noti_id) {
        const find = resNoti.data.find((v) => v._id === noti_id)
        if (find) {
          var _arr = [...rows]
          const findNotiIndex = _arr.findIndex((v) => v._id === noti_id)
          if (findNotiIndex > -1) {
            _arr[findNotiIndex] = find
          }
          setRows(_arr)
        }
      } else {
        setRows((prev) => [...prev, ...resNoti.data])
      }
    } catch (e) {
    } finally {
      setLoading(false)
    }
  }

  const fetchCountNoti = async () => {
    try {
      const { data } = await apiNotification.getCountNoti({ user_type: 2 })
      if (data > 0) {
        setHasNewNoti(true)
      } else {
        setHasNewNoti(false)
      }
    } catch (e) {
    } finally {
    }
  }

  function setLoadingItem(id, isLoading) {
    setRows((v) => {
      let _new = [...v]
      v.forEach((item, index) => {
        if (item._id === id) {
          _new[index].loading = isLoading
        }
      })
      return _new
    })
  }

  const fetchDetail = async (id, value, noti_id, is_read) => {
    setLoadingItem(noti_id, true)
    try {
      const { data } = await apiApproval.getDetailApproval(id)
      __item.current = data
      onUpdateStatus(id, value, noti_id)
    } catch (e) {
      setLoadingItem(noti_id, false)
    } finally {
    }
  }

  const onMarkReadNoti = async (id) => {
    try {
      await apiNotification.markReadByNoti(id)
    } catch (e) {
    } finally {
      // fetchNotiByCategory(id)
      fetch(id)
      fetchCountNoti()
    }
  }

  const onUpdateStatus = async (id, v, noti_id) => {
    try {
      const { success } = await apiApproval.updateApprovalConfirm(id, {
        approved: v
      })
      if (success === true) {
        if (v) {
          notification.success({ description: t("467_4297") })
        } else {
          notification.success({ description: t("467_4298") })
        }
      }
      // onMarkReadNoti(noti_id)
    } catch (error) {
    } finally {
      setLoadingItem(noti_id, false)
      onMarkReadNoti(noti_id)
    }
  }

  async function onChange(v) {
    setActiveKey(v)
    setRows([])
    __pagination.current.page_num = 1
    __pagination.current.category_id = v
    fetch()
    // fetchNotiByCategory()
  }

  const onViewDetail = (item) => {
    const { data, _id, is_read } = item
    if (data?.approval_setting) {
      if (data?.status !== Approval_Status.in_process && !is_read) {
        onMarkReadNoti(_id)
      }
    } else {
      if (!is_read) {
        onMarkReadNoti(_id)
      }
    }

    setIsComponentVisible(false)
    navigate(`/notification/${_id}`, { state: { data: item } })
  }

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current
      const isNearBottom = scrollTop + clientHeight >= scrollHeight

      if (isNearBottom && __pagination.current.has_next) {
        // DO SOMETHING HERE
        __pagination.current.page_num = __pagination.current.page_num + 1
        fetchNotiByCategory()
      }
    }
  }

  useEffect(() => {
    const listInnerElement = listInnerRef.current

    if (listInnerElement) {
      listInnerElement.addEventListener("scroll", onScroll)

      // Clean-up
      return () => {
        listInnerElement.removeEventListener("scroll", onScroll)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isComponentVisible])

  useEffect(() => {
    fetch()
    fetchCountNoti()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getCategoryName = (name) => {
    let translatedName = ""
    switch (name) {
      case "General":
        translatedName = t("I385_2826;405_11590;405_11754")
        break
      case "Approval":
        translatedName = t("I385_2826;405_13262;405_11583")
        break
      case "Tasks":
        translatedName = t("I385_2826;405_13268;405_11583")
        break
      case "Inbox":
        translatedName = t("I385_2826;405_13251;405_11583")
        break
    }
    return translatedName
  }

  return (
    <div className="w-10 cursor-pointer flex justify-center items-center">
      <Badge dot={hasNewNoti ? true : false} offset={[-5, 1]}>
        <div onClick={() => setIsComponentVisible(true)}>{icNoti}</div>
      </Badge>
      <div ref={ref}>
        {isComponentVisible && (
          <div className="fixed right-10 w-[460px] top-16 z-20 shadow bg-white px-4 py-4 rounded">
            <Spin spinning={loading}>
              <Tabs
                activeKey={activeKey}
                items={categories.map((i, key) => {
                  return {
                    key: i.id,
                    label: (
                      <div className="flex gap-1">
                        <div
                          className="font-medium text-base"
                          style={{ color: i.id === activeKey ? "#1C3F38" : "#00000040" }}
                        >
                          {getCategoryName(i.name)}
                        </div>
                        {i.count > 0 && (
                          <Badge
                            count={i.count}
                            color={i.id === activeKey ? "#1C3F38" : "#00000040"}
                            overflowCount={99}
                          />
                        )}
                      </div>
                    )
                  }
                })}
                onChange={onChange}
              />

              <div className="h-[450px] overflow-y-auto" ref={listInnerRef}>
                {rows?.length > 0 ? (
                  rows?.map((i, key) => {
                    return (
                      <div
                        key={key}
                        className="p-2 mb-3 shadow border border-slate-300 rounded"
                        onClick={() => onViewDetail(i)}
                      >
                        {i.type === TYPE_BLOCK_NOTI.APPROVAL && (
                          <BoxApproval item={i} onConfirm={fetchDetail} />
                        )}
                        {i.type === TYPE_BLOCK_NOTI.NEWS && <BoxOther item={i} />}
                        {i.type === TYPE_BLOCK_NOTI.MARKETING && <BoxOther item={i} />}
                        {i.type === TYPE_BLOCK_NOTI.PROMOTION && <BoxOther item={i} />}
                        {i.type === TYPE_BLOCK_NOTI.EVENT && <BoxOther item={i} />}
                        {i.type === TYPE_BLOCK_NOTI.TASK && <BoxOther item={i} />}
                      </div>
                    )
                  })
                ) : (
                  <div className="flex justify-center items-center">{t("423_1952")}</div>
                )}
              </div>
              <div className="mt-3 mr-3 flex justify-end">
                <Link
                  className="!text-black !underline !decoration-solid"
                  to="/notification"
                  onClick={() => setIsComponentVisible(false)}
                >
                  {t("385_2869")}
                </Link>
              </div>
            </Spin>
          </div>
        )}
      </div>
    </div>
  )
}

const icNoti = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.0001 11.143V8.57153C18.0001 6.98023 17.368 5.45411 16.2428 4.32889C15.1176 3.20367 13.5914 2.57153 12.0001 2.57153C10.4088 2.57153 8.88272 3.20367 7.7575 4.32889C6.63228 5.45411 6.00014 6.98023 6.00014 8.57153V11.143C6.00014 13.9715 3.42871 14.6572 3.42871 16.2858C3.42871 17.743 6.77157 18.8572 12.0001 18.8572C17.2287 18.8572 20.5716 17.743 20.5716 16.2858C20.5716 14.6572 18.0001 13.9715 18.0001 11.143Z"
      stroke="#397E79"
      strokeWidth="2.05714"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M11.9999 20.5713C11.1333 20.5713 10.319 20.5422 9.55273 20.4856C9.7161 21.0067 10.0416 21.4621 10.4817 21.7854C10.9219 22.1087 11.4537 22.283 11.9999 22.283C12.546 22.283 13.0779 22.1087 13.518 21.7854C13.9582 21.4621 14.2837 21.0067 14.447 20.4856C13.6807 20.5422 12.8664 20.5713 11.9999 20.5713Z"
      fill="#397E79"
    />
  </svg>
)
