import Collapse from "components/Collapse"
import { useEffect, useState } from "react"
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
let activeClassname

export default function Navbar({ items, isNav }) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const location = useLocation()
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    if (isNav === "left-0") {
      if (document.body.style.overflow !== "hidden") {
        document.body.style.overflow = "hidden"
      }
    }
    setIsVisible(false)
    return () => {
      document.body.style.overflow = null
      document.body.style.width = null
    }
  }, [isNav])
  const wapperClass = isNav ? "show__sider" : "hide__sider"

  return (
    <div
      className={`__bg z-10 sticky text-slate-400 h-screen border-r border-slate-700 top-0 shadow ease-in-out duration-300 ${wapperClass}`}
    >
      <div
        className={`gap-3 border-b justify-center py-3 border-slate-700 px-6 h-14 flex text-xl font-bold items-center text-slate-50`}
      >
        <img
          src={isNav ? "/logo.png" : "/logo-small.png"}
          alt="logo"
          className={`${isNav ? "h-full" : "h-4/5"} cursor-pointer logo`}
          onClick={() => navigate("/dashboards")}
        />
      </div>
      <div className={isNav ? "overflow-y-auto __scroll" : ""}>
        <div className="flex flex-col py-4 text-sm">
          <ul className="space-y-2">
            {items.map((item) => {
              const { url, title, icon, children } = item
              if (children && children.length > 0) {
                return (
                  <MenuItem
                    t={t}
                    {...item}
                    key={url}
                    location={location}
                    isNav={isNav}
                    isVisible={isVisible}
                    setIsVisible={setIsVisible}
                  />
                )
              } else {
                if (location.pathname.includes(url)) {
                  activeClassname = "sidebar_active_color text-white"
                } else {
                  activeClassname = "text-white hover:bg-white/20"
                }
                return (
                  <li key={url} className="mb-2" onMouseOut={() => setIsVisible(false)}>
                    <NavLink to={url} target={url.includes("http") ? "_blank" : ""}>
                      <div className={`${activeClassname} menu__item relative`}>
                        {icon}
                        <span className="text-ellipsis flex-auto overflow-hidden whitespace-nowrap">
                          {t(title)}
                        </span>
                        {!isNav && (
                          <div className="tooltip_title_not_sub hidden text-gray-50 absolute left-[72px] bg-gray-800 p-3 rounded top-0 w-max">
                            {t(title)}
                          </div>
                        )}
                      </div>
                    </NavLink>
                  </li>
                )
              }
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}

function MenuItem({ title, icon, url, children, location, isNav, t, isVisible, setIsVisible }) {
  const [isOpen, setOpen] = useState(children.find((i) => location.pathname.includes(i.url)))

  function onTogle() {
    setOpen((c) => !c)
  }
  if (location.pathname.includes(url)) {
    activeClassname = "sidebar_active_color text-white"
  } else {
    activeClassname = "text-white hover:bg-white/20"
  }
  const classIsDown = isOpen ? "rotate-180" : "rotate-0"

  return (
    <div className="sub__menu relative">
      <li onMouseOver={() => setIsVisible(true)}>
        <div
          onClick={onTogle}
          className={`${activeClassname} cursor-pointer h-11 px-8 flex items-center justify-between gap-4`}
        >
          <div className="flex w-full items-center gap-4">
            {icon}
            <span className="text-ellipsis flex-auto overflow-hidden whitespace-nowrap">
              {t(title)}
            </span>
          </div>
          {isNav && (
            <div className={`${classIsDown} flex items-center transition-all duration-300`}>
              {icDown}
            </div>
          )}
        </div>
        {!isNav && isVisible && (
          <div
            className="absolute top-0 left-[60px] ml-[28px] __bg rounded"
            onMouseOver={() => setIsVisible(true)}
            onMouseOut={() => setIsVisible(false)}
          >
            <ul className="py-2 space-y-2 custom_bg_white">
              {children.map((item) => {
                return (
                  <li key={`${item.url}-${url}`}>
                    <NavLink
                      to={`${item.url}`}
                      className={({ isActive }) =>
                        isActive
                          ? "flex items-center sidebar_active_color text-white gap-4 py-1 px-8 __active__sub__menu"
                          : "flex items-center sidebar-text-sub text-slate-100 gap-4 py-1 px-8"
                      }
                    >
                      <span className="px-4 text-ellipsis flex items-center gap-2 flex-auto overflow-hidden whitespace-nowrap">
                        {item?.icon}
                        {t(item.title)}
                      </span>
                    </NavLink>
                  </li>
                )
              })}
            </ul>
          </div>
        )}
      </li>
      <Collapse isOpen={isOpen} isShow={isNav}>
        <ul className="py-2 space-y-2 custom_bg_white">
          {children.map((item) => {
            return (
              <li key={`${item.url}-${url}`}>
                <NavLink
                  to={`${item.url}`}
                  className={({ isActive }) =>
                    isActive
                      ? "flex items-center sidebar_active_color text-white gap-4 py-1 px-8 __active__sub__menu"
                      : "flex items-center sidebar-text-sub text-slate-100 gap-4 py-1 px-8"
                  }
                >
                  <span className="px-4 text-ellipsis flex items-center gap-2 flex-auto overflow-hidden whitespace-nowrap">
                    {item?.icon}
                    {t(item.title)}
                  </span>
                </NavLink>
              </li>
            )
          })}
        </ul>
      </Collapse>
    </div>
  )
}

const icDown = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-4 w-4"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeMiterlimit={2}
    strokeWidth={2}
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
  </svg>
)
